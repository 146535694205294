import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as style from "./404.module.scss"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Helmet bodyAttributes={{ class: "four_o_four" }} />
    <section className={style.wrapper}>
      <div className={style.content}>
        <h1>Uh-oh!</h1>
        <p>
          Sorry but you seem to be looking for something that isn't here, or the
          corgi changed the URL.
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
